html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.App {
  font-family: 'Times New Roman', Times, serif;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #282c34;
  text-align: center;
  color: #171717;
  text-align: left;
  background-color: #f6f6f6;
}

h1 {
  font-size: 5.5rem;
  margin: 0;
  font-weight: 500;
}

h2 {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
}

hr {
  width: 80%;
  border: 1px solid #171717;
  margin: 20px 0;
}

p {
  font-size: 1.6rem;
  line-height: 2;
  margin: 0;
  padding-bottom: 15vh;
}
